var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pricing row" }, [
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", { attrs: { for: "companysize" } }, [
        _vm._v(_vm._s(_vm.$t("wählen_sie")))
      ]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selected,
              expression: "selected"
            }
          ],
          staticClass: "custom-select",
          attrs: { id: "companysize" },
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selected = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        _vm._l(_vm.emplyeeCount, function(count) {
          return _c("option", { domProps: { value: count.value } }, [
            _vm._v(_vm._s(count.count))
          ])
        }),
        0
      )
    ]),
    _c(
      "div",
      { staticClass: "col-md-6" },
      [_c("price-overview", { attrs: { price: _vm.getSelected() } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }