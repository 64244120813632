var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "overview" }, [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-row justify-content-between mt-5" },
          [
            _c("div", { staticClass: "pl-0" }, [
              _vm._v(_vm._s(_vm.$t("zahlung")))
            ]),
            _c("div", { staticClass: "price lead font-weight-bold" }, [
              _vm._v(_vm._s(_vm.formatNumber(_vm.price.Netto)))
            ])
          ]
        ),
        _c("hr", { staticClass: "mt-3" }),
        _c("div", { staticClass: "d-flex flex-row justify-content-center" }, [
          _c(
            "a",
            {
              staticClass: "genric-btn primary circle arrow",
              attrs: {
                type: "button",
                href: "https://login.empfehlungsbund.de/registrieren/",
                target: "_blank"
              }
            },
            [_vm._v(_vm._s(_vm.$t("registrieren")))]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }